@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custom-container {
  margin-top: calc(var(--space-v-section) * -2.5);
  margin-bottom: calc(var(--space-v-section) * -2.5);
  z-index: 2;
}

.custom-container .container-default {
  background-color: rgb(var(--color-accent));
  box-shadow: 0px 5px 12px 1px rgba(var(--color-accent), .3);
}
